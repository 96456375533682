<template>
  <div class="use">
      <img class="topImg" src="@/assets/useImg/useImg.png" alt="">
      <div class="useContent">
          <div class="useText">
              <p class="textTitle">干饭之选，你点餐我买单</p>
              <p class="textContent">剁手记与各大餐饮合作。不定时推出返利活动，在您用餐时也可分分钟赚钱。通过扫码下载剁手记，即可获得优惠商品一份。加剁手记官方客服，我们工作人员每日发送优惠商品，手把手教你如何分享赚钱。</p>
          </div>
          <img src="@/assets/useImg/img1.png" alt="">
      </div>
      <div class="useContent teBg">
          <img class="teImg" src="@/assets/useImg/img2.png" alt="">
          <div class="useText">
              <p class="textTitle">生命在于运动，返利就用剁手记</p>
              <p class="textContent">剁手记与各大俱乐部合作，在俱乐部内下载使用剁手记即可获得精美小礼物一份。更多俱乐部优惠活动，剁手记将第一时间通知您。</p>
          </div>
      </div>
      <div class="useContent ">
          <div class="useText">
              <p class="textTitle">宝妈</p>
              <p class="textContent">你是不是很熟悉这样一个场景：为了照顾好孩子，做起了全职妈妈，随着孩子日渐长大，开销也日常增多，想想出去工作吧，怕离开岗位这么久，工作没那么好找了，就算上了班白天工作回来还要照顾这个家，可能比现在还要累。那该怎么办呢？唉，这也曾经是我的困恼，好在我有了剁手记。起初我主要用剁手记去买些奶粉、尿布等日常用品，可以在618、双十一的优惠价格上再拿返现，收到货以后我会把使用心得通过剁手记分享给其他宝妈，想不到她们购买之后我还能赚取佣金，简直发现了宝藏。我开始利用带小孩的空隙时间，坐下来动动手指把我平时的带娃心得，好用的产品分享到宝妈群里，刚开始每个月就能挣到一两千，后来关注我的宝妈越来越多，每个月的收入也越来越高，不比出去上班赚的少，还不耽误照顾家庭和孩子，剁手记就像是为我这种待在家的宝妈量身定做一样。连我的老公都开始很羡慕我，也想参与进来呢。哈哈！</p>
          </div>
          <img src="@/assets/useImg/img3.png" alt="">
      </div>
       <div class="useContent teBg">
           <img class="teImg" src="@/assets/useImg/img4.png" alt="">
          <div class="useText">
              <p class="textTitle">上班族</p>
              <p class="textContent">唉～又到了发工资的这天，却根本开心不起来，去掉房租和水电煤到手可支配却只剩两三千。别说存钱，外卖都要叫不起了。可同样收入的小美，她却能每天叫外卖之外还经常换不同品牌的衣服。后来的一天我终于忍不住好奇心请教了她。她告诉我，普通上班族要想生活过得好，除了日常开销要节省，还要把空闲时间利用起来做副业赚钱，但做副业哪有那么容易呢！一筹莫展之际，她推荐我使用剁手记，在剁手记里购物不光有优惠券拿，购物后还有返现，连美团、饿了么也有优惠及返现，有时一单能节约好几十；并且还能通过分享给别人来赚取佣金，难怪她朋友圈经常分享一些优惠购物信息。刚开始我还有些担心，使用后发现原来剁手记是对接了淘宝、京东、美团、饿了么这些知名电商平台，购物使用都通过这些大平台，产品的品质与服务都有保障，返现的金额也是真实获得，这样我就放心的慢慢做了起来。到今天我的副业已经比我的主业还要赚钱了，庆幸自己当时选择了剁手记，不然现在还不知道明天的房租该怎么办呢！</p>
          </div>
      </div>
      <div class="useContent">
          <div class="useText">
              <p class="textTitle">学生</p>
              <p class="textContent">大多数人都觉得大学生过得轻松，吃喝不愁，但是实际上呢？大学生压力也很大，家里的父母年事已高，不想再增加他们的负担，平日想要打工怕耽误学习，到了寒暑假去实习，收入也不多；担心的就是毕业以后，如果不能马上找到好的工作那每个月房租日常开销都不小。好在我的一个学长给我分享了剁手记，告诉我日常网上购物就可用剁手来拿返现节约日常开销，积少成多，能省下不少钱！同时可以把链接分享给小伙伴，赚取佣金，一开始我们寝室用下来果然后省了不少钱，虽然不多，但是一个月积累下来可以不少呢，后来推荐给周围同学用，慢慢的整个班级还有整个宿舍的人都在用，连宿管阿姨也让我教她如何拿返现和分享赚钱。现在通过剁手记赚的钱不光让我的生活费不用再依靠父母，多余的钱我也存了起来，有了剁手记我有信心在社会上站稳脚跟!</p>
          </div>
          <img src="@/assets/useImg/img5.png" alt="">
      </div>
  </div>
</template>

<script>
export default {

}

</script>
<style  scoped>
.use{

}
.topImg{
    width:100%;
    height: 3.2rem;
}
.useContent{
    width:100%;
    height:6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.useText{
    display: flex;
    flex-direction: column;
    text-align: left;
    
}
.textTitle{
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
    margin-bottom: 11px;
}
.textContent{
    width: 5.22rem;
    font-size: 0.14rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.2rem;
}
.useContent > img{
    width:5.2rem;
    height:2.9rem;
    margin-left:2.37rem;
}
.teImg{
    margin-left:0 !important;
    margin-right: 2.37rem;
}
.teBg{
    background: #F8F8F8;
}
</style>